<template>
	<div id="buy">
		<div class="logo">
			<img src="/static/img/sole_tracking.png" alt="">
		</div>
		<!-- ======= Payment Section ======= -->
		<section id="payments" class="payment">
			<div class="container">

				<div class="section-title">
					<h2>Comprar</h2>
				</div>

				<div class="paymentForm">
					<form role="form" id="formPayment" class="formPayment php-email-form" novalidate>
						<div class="selectProduct">
							<h3>Selecciona tus productos</h3>
							<div class="row row-cols-1 row-cols-md-2 showProduct">
								<div class="col-xs-4" v-for="product in shopProducts">
									<div class="card">
										<img v-bind:src="product.img" class="card-img-top">
										<div class="card-body">
											<h5 class="card-title">{{product.nombre}}</h5>
											<p class="card-text">${{product.costo}}</p>
										</div>
										<div class="card-footer">
											<div class="coverBtn" v-on:click="agregarCarrito(product)">
												Agregar a carrito
											</div>
											<!--<input type="text" v-bind:value="product.cantidad">-->
										</div>
									</div>
									<!--<div class="form-group row cantidadKids">
										<label for="cantidadKids" class="col-sm-6 col-form-label">Cantidad</label>
										<input type="number" class="col-sm-6 form-control noRequired" id="cantidadKids" name="cantidadKids" min="0" max="10">
									</div>
									<div class="form-group row totalKids">
										<label class="col-sm-6 col-form-label">Total</label>
										<p class="col-sm-6"> $ 1,000.00</p>
									</div>-->
								</div>
							</div>
						</div>
						<div class="shippingAddress">
							<h3>Dirección de envío</h3>
							<div class="row">
								<div class="form-group col-lg-8 col-md-12">
									<input placeholder="Calle" type="text" name="calle" class="form-control" id="calle" required />
									<div class="invalid-feedback">Campo obligatorio</div>	
								</div>
								<div class="form-group col-lg-2 col-md-6">
									<input placeholder="Núm ext" type="text" name="numExt" class="form-control" id="numExt" required />
									<div class="invalid-feedback">Campo obligatorio</div>
								</div>
								<div class="form-group col-lg-2 col-md-6">
									<input placeholder="Núm int" type="text" name="numInt" class="form-control noRequired" id="numInt" />
								</div>
							</div>
							<div class="row">
								<div class="form-group col-lg-6 col-md-6">
									<input placeholder="Cruce 1" type="text" name="entreCalle" class="form-control noRequired" id="entreCalle" />
								</div>
								<div class="form-group col-lg-6 col-md-6">
									<input placeholder="Cruce 2" type="text" name="entreCalle2" class="form-control noRequired" id="entreCalle2" />
								</div>
							</div>
							<div class="row">
								<div class="form-group col-lg-8">
									<input placeholder="Colonia" type="text" name="colonia" class="form-control" id="colonia" required />
									<div class="invalid-feedback">Campo obligatorio</div>
								</div>
								<div class="form-group col-lg-4">
									<input placeholder="C.P." type="text" name="cp" class="form-control" id="cp" minlength="5" maxlength="5" required />
									<div class="invalid-feedback">Ingresa un código postal válido</div>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-lg-4">
									<input placeholder="Municipio" type="text" name="municipio" class="form-control" id="municipio" required />
									<div class="invalid-feedback">Campo obligatorio</div>
								</div>
								<div id="estados" class="form-group col-lg-4">
									<select class="form-control" id="selectEstado" required>
										<option value="">Estado</option>
										<option value="Aguascalientes">Aguascalientes</option>
										<option value="Baja California">Baja California</option>
										<option value="Baja California Sur">Baja California Sur</option>
										<option value="Campeche">Campeche</option>
										<option value="Coahuila">Coahuila</option>
										<option value="Colima">Colima</option>
										<option value="Chiapas">Chiapas</option>
										<option value="Chihuahua">Chihuahua</option>
										<option value="Ciudad de México">Ciudad de México</option>
										<option value="Durango">Durango</option>
										<option value="Guanajuato">Guanajuato</option>
										<option value="Guerrero">Guerrero</option>
										<option value="Hidalgo">Hidalgo</option>
										<option value="Jalisco">Jalisco</option>
										<option value="Estado de México">Estado de México</option>
										<option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
										<option value="Morelos">Morelos</option>
										<option value="Nayarit">Nayarit</option>
										<option value="Nuevo León">Nuevo León</option>
										<option value="Oaxaca">Oaxaca</option>
										<option value="Puebla">Puebla</option>
										<option value="Querétaro">Querétaro</option>
										<option value="Quintana Roo">Quintana Roo</option>
										<option value="San Luis Potosí">San Luis Potosí</option>
										<option value="Sinaloa">Sinaloa</option>
										<option value="Sonora">Sonora</option>
										<option value="Tabasco">Tabasco</option>
										<option value="Tamaulipas">Tamaulipas</option>
										<option value="Tlaxcala">Tlaxcala</option>
										<option value="Veracruz">Veracruz</option>
										<option value="Yucatán">Yucatán</option>
										<option value="Zacatecas">Zacatecas</option>
									</select>
									<div class="invalid-feedback">Selecciona un estado</div>
								</div>
								<div class="form-group col-lg-4">
									<input placeholder="País" type="text" name="pais" class="form-control" id="pais" value="México" readonly />
								</div>
							</div>
							<div class="form-group">
								<textarea class="form-control noRequired" name="infoAdicional" id="infoAdicional" placeholder="Información adicional" rows="1"></textarea>
							</div>	
						</div>
						
						<div class="pay">
							<div class="row">
								<div id="card" class="col-lg-6">
									<h3>Pago</h3>
								    <!--<div class="card-js mycardjs" data-icon-colour="#007F97">
								    	<input class="name form-control" id="the-card-name-id" name="card-holders-name" placeholder="Nombre del titular" autocomplete="off" required />
										<input class="card-number my-custom-class form-control" name="card-number" placeholder="Número de tarjeta" autocomplete="off" required >
										<input class="expiry form-control" autocomplete="off" required>
										<input class="cvc form-control" name="cvc" placeholder="CVV" required >
									</div>-->
									<div class="card-js">
									    <input class="card-number my-custom-class">
									    <input class="name" id="the-card-name-element">
									    <input class="expiry-month">
									    <input class="expiry-year">
									    <input class="cvc">
									</div>

									<div class="row">
										<div class="col-lg-6 logo">Transacciones realizadas vía:
										</div>
										<div class="col-lg-6 shield">Tus pagos se realizan de forma segura con encriptación de 256 bits
										</div>
									</div>

									<div class="form-check col-lg-12 radiosPago">
									  	<input class="form-check-input noRequired" type="radio" name="radioTipoPago" id="radioContado" value="0" checked onchange="javascript:showMSI()">
									  	<label class="form-check-label" for="radioContado">Pago de contado</label>
									</div>
									<div class="row">
										<div class="form-check col-lg-9 MSI">
										  	<input class="form-check-input noRequired" type="radio" name="radioTipoPago" id="radioMSI" value="1" onchange="javascript:showMSI()">
										  	<label class="form-check-label" for="radioMSI">Pago diferido a meses sin intereses</label>
										  	<div class="methods" onclick="showMethodsPayment('#paymentMethods')">
												<small id="bancosParticipantes" class="form-text text-muted">¿Cuáles bancos participan?</small>
											</div>
										</div>
										<div id="MSI" class="form-group col-lg-3 checkMSI">
											<select class="form-control noRequired" id="selectMSI">
												<option>3</option>
												<option>6</option>
												<option>9</option>
												<option>12</option>
												<option>18</option>
											</select>
										</div>		
									</div>									
									
									<div class="form-check">
									    <input type="checkbox" class="form-check-input" name="checkBilling" id="checkBilling"  value="1" onchange="javascript:showBilling()">
									    <label class="form-check-label" for="checkBilling">Requiero factura</label>
									</div>
								</div>
								<div class="detallesPedido col-lg-6">
									<h3>Detalles de tu pedido</h3>
									<div class="row">
										<div class="col-lg-8">
											<p>Dispositivo 1 (3):</p>
											<p>Dispositivo 2 (1):</p>
											<p>Subtotal: </p>
											<p>I.V.A.:</p>
											<p>Envío:</p>
											<p>Total: </p>
										</div>
										<div class="col-lg-4">
											<p>$ 3,000.00</p>
											<p>$ 1,000.00</p>
											<p>$ 4,000.00</p>
											<p>$ 640.00</p>
											<p>$ 0.00</p>
											<p>$ 4,640.00</p>
										</div>
									</div>
								</div>
							</div>

							<div id="billing">
								<h3>Facturación</h3>
								<div class="personaFisicaMoral">
									<div class="form-check form-check-inline">
									    <input class="form-check-input" type="radio" name="personaFisicaMoral" id="personaFisica" value="Fisica">
									    <label class="form-check-label" for="personaFisica">Persona física</label>
									</div>
									<div class="form-check form-check-inline">
									    <input class="form-check-input" type="radio" name="personaFisicaMoral" id="personaMoral" value="Moral">
									    <label class="form-check-label" for="personaMoral">Persona moral</label>
									</div>
								</div>									
								<div class="form-group">
									<input placeholder="Nombre o razón social" type="text" name="nombreFactura" class="form-control" id="nombreFactura" />
									<div class="invalid-feedback">Campo obligatorio</div>
								</div>
								<div class="row">
									<div class="form-group col-lg-6">
										<input placeholder="RFC" type="text" name="rfc" class="form-control" id="rfc" />
										<div class="invalid-feedback">Campo obligatorio</div>
									</div>
									<div id="cfdi" class="form-group col-lg-6">
										<select class="form-control" id="selectCFDI" name="selectCFDI">
											<option value="">CFDI</option>
											<option value="G01">G01 - Adquisición de mercancías</option>
											<option value="G03">G03 - Gastos en general</option>
											<option value="I06">I06 - Comunicaciones telefónicas</option>
											<option value="I07">I07 - Comunicaciones satelitales</option>
											<option value="I08">I08 - Otras máquinas y equipo</option>
											<option value="P01">P01 - Por definir</option>
										</select>
										<div class="invalid-feedback">Campo obligatorio</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-lg-6">
										<input placeholder="Teléfono" type="text" name="telefonoFactura" class="form-control" id="telefonoFactura" />
										<div class="invalid-feedback">Campo obligatorio</div>
									</div>
									<div class="form-group col-lg-6">
										<input placeholder="Correo electrónico" type="email" name="emailFactura" class="form-control" id="emailFactura" data-rule="email" data-msg="Ingresa un correo electrónico válido" />
										<div class="invalid-feedback">Campo obligatorio</div>
									</div>									
								</div>							
								
								<div class="form-check">
								    <input type="checkbox" class="form-check-input" name="checkTaxResidence" id="checkTaxResidence"  value="1" onchange="javascript:showTaxResidence()">
								    <label class="form-check-label" for="checkTaxResidence">El domicilio físcal es diferente a la dirección de envío</label>
								</div>

								<div id="taxResidence">
									<div class="row">
										<div class="form-group col-lg-8">
											<input placeholder="Calle" type="text" name="calleFactura" class="form-control" id="calleFactura" />
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
										<div class="form-group col-lg-2">
											<input placeholder="Núm ext" type="text" name="numExt" class="form-control" id="numExtFactura" />
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
										<div class="form-group col-lg-2">
											<input placeholder="Núm int" type="text" name="numInt" class="form-control noRequired" id="numIntFactura" />
										</div>
									</div>
									<div class="row">
										<div class="form-group col-lg-8">
											<input placeholder="Colonia" type="text" name="coloniaFactura" class="form-control" id="coloniaFactura"  />
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
										<div class="form-group col-lg-4">
											<input placeholder="C.P." type="text" name="cpFactura" class="form-control" id="cpFactura" />
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
									</div>
									<div class="row">
										<div class="form-group col-lg-4">
											<input placeholder="Municipio" type="text" name="municipioFactura" class="form-control" id="municipioFactura" />
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
										<div id="estadosFactura" class="form-group col-lg-4">
											<select class="form-control" id="selectEstadoFactura" name="selectEstadoFactura">
												<option value="">Estado</option>
												<option value="Aguascalientes">Aguascalientes</option>
												<option value="Baja California">Baja California</option>
												<option value="Baja California Sur">Baja California Sur</option>
												<option value="Campeche">Campeche</option>
												<option value="Coahuila">Coahuila</option>
												<option value="Colima">Colima</option>
												<option value="Chiapas">Chiapas</option>
												<option value="Chihuahua">Chihuahua</option>
												<option value="Ciudad de México">Ciudad de México</option>
												<option value="Durango">Durango</option>
												<option value="Guanajuato">Guanajuato</option>
												<option value="Guerrero">Guerrero</option>
												<option value="Hidalgo">Hidalgo</option>
												<option value="Jalisco">Jalisco</option>
												<option value="Estado de México">Estado de México</option>
												<option value="Michoacán de Ocampo">Michoacán de Ocampo</option>
												<option value="Morelos">Morelos</option>
												<option value="Nayarit">Nayarit</option>
												<option value="Nuevo León">Nuevo León</option>
												<option value="Oaxaca">Oaxaca</option>
												<option value="Puebla">Puebla</option>
												<option value="Querétaro">Querétaro</option>
												<option value="Quintana Roo">Quintana Roo</option>
												<option value="San Luis Potosí">San Luis Potosí</option>
												<option value="Sinaloa">Sinaloa</option>
												<option value="Sonora">Sonora</option>
												<option value="Tabasco">Tabasco</option>
												<option value="Tamaulipas">Tamaulipas</option>
												<option value="Tlaxcala">Tlaxcala</option>
												<option value="Veracruz">Veracruz</option>
												<option value="Yucatán">Yucatán</option>
												<option value="Zacatecas">Zacatecas</option>
											</select>
											<div class="invalid-feedback">Campo obligatorio</div>
										</div>
										<div class="form-group col-lg-4">
											<input placeholder="País" type="text" name="pais" class="form-control" id="paisFactura" value="México" readonly />
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div class="text-center">
							<button id="pagar" v-on:click="funcionPagar()" type="button">Pagar</button>
						</div>
					</form>
				</div>
			</div>

			<aside id="carritoLand" v-if="carritoList.length > 0">
				<h2>Carrito</h2>
				<ul>
					<li v-for="item in carritoList">
						<img v-bind:src="item.img">
						<p>{{item.nombre}}</p>
						<span><strong>${{item.costo}}</strong></span>
						<div>
							<span class="btnCant">Cantidad</span>
							<input type="text" v-model="item.cantidad" v-on:change="calcularTotal()">
						</div>						
					</li>
				</ul>
				<div id="totalCarrito">
					<h2>Total: ${{totalCarrito}}</h2>
				</div>
			</aside>
		</section><!-- End Payment Section -->

		<div class="modal fade" id="paymentMethods" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalCenterTitle">Bancos participantes para meses sin intereses (3, 6, 9, 12 y 18)</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<p>INSERTE LOGOS DE BANCOS
						</p>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
					</div>
				</div>
			</div>
		</div>
	</div>		
</template>

<script> 
import { request, mapActions} from 'vuex';

	export default { 
		data(){
			return{
				shopProducts: [],
				carritoList: [],
				totalCarrito: 0.0
			}
		}, 
		created() { 
			var instance = this;

			let recaptchaScriptSolePayment = document.createElement('script') 
			recaptchaScriptSolePayment.setAttribute('src','/static/js/solePayment.js') 
			document.head.appendChild(recaptchaScriptSolePayment)

			let recaptchaCssCardJs = document.createElement('link') 
			recaptchaCssCardJs.setAttribute('href','/static/cardjs/card-js.min.css') 
			document.head.appendChild(recaptchaCssCardJs)

			let recaptchaScriptCardJs = document.createElement('script') 
			recaptchaScriptCardJs.setAttribute('src','/static/cardjs/card-js.min.js') 
			document.head.appendChild(recaptchaScriptCardJs) 


			this.getProducts();

		}, 
		methods: { 
			...mapActions(["getAll"]),
			/*Funcion que esta conectada a la funcion de*/
			funcionPagar: function(){
				var form = document.getElementById('formPayment');

				if (form.checkValidity() === false) {
		          console.log("VALIDITY = FALSE")
		        }else{
		        	console.log("validity = true")
		        	
		        }
		        form.classList.add('was-validated');		        
			},

			getProducts(){
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	var instance = this;

		    	let payload = {
		    		endpoint: "/api/producto/todos",
		    		token: token
		    	};

		    	this.getAll(payload).then(response => {
		    		instance.shopProducts = response.data.data;
		    		instance.shopProducts.forEach(element => {
		    			element.img = "https://picsum.photos/200";
		    			element.cantidad = 1;
		    		});
		    	}).catch(function(error){
		            instance.$toasted.error(error.msg);
		            instance.$parent.toggleLoader();
		        });
		    },

		    agregarCarrito(product){
		    	var productoExiste = false;
		    	var instance = this;
		    	//verificar si ya está en carro
		    	this.carritoList.forEach(function(element){
		    		if(element.id === product.id){
		    			element.cantidad = element.cantidad + 1;
		    			productoExiste = true;
		    		}
		    	});

		    	if(!productoExiste){
		    		this.carritoList.push(product);	
		    	}

		    	this.calcularTotal();
		    },

		    calcularTotal: function(){
				var resultado = 0.0;

		    	this.carritoList.forEach(function(element){
		    		resultado = resultado + (element.costo * element.cantidad);
		    	});

		    	this.totalCarrito = resultado;
			}
		} 
	} 
</script> 
<style scoped type="text/css" src="../../assets/css/style.css"></style>
